import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';

const ClassEnrollment: React.FC = () => {
  const [classCode, setClassCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleEnroll = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await axios.post(`/classes/${classCode}/enroll/`, { class_code: classCode });
      setSuccess('Successfully enrolled in the class!');
      setClassCode('');
    } catch (error) {
      console.error('Error enrolling in class:', error);
      setError('Failed to enroll in class. Please check the class code and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Enroll in a Class</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
      <TextField
        fullWidth
        label="Class Code"
        value={classCode}
        onChange={(e) => setClassCode(e.target.value)}
        margin="normal"
      />
      <Button 
        variant="contained" 
        onClick={handleEnroll}
        disabled={loading || !classCode}
      >
        {loading ? <CircularProgress size={24} /> : 'Enroll'}
      </Button>
    </Box>
  );
};

export default ClassEnrollment;