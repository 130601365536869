import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  TextField,
  CircularProgress,
  Chip,
  Avatar,
  Alert,
} from "@mui/material";
import {
  Create,
  Explore,
  TrendingUp,
  School,
  People,
  MenuBook,
} from "@mui/icons-material";
import axios from "axios";

interface StoryPreview {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  author?: string;
  current_node?: {
    image_url: string;
  };
}

interface ClassPreview {
  id: number;
  name: string;
  description: string;
  students_count: number;
  stories_count: number;
  owner: string;
}


const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';


const Home: React.FC = () => {
  const [stories, setStories] = useState<StoryPreview[]>([]);
  const [popularClasses, setPopularClasses] = useState<ClassPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [quickTitle, setQuickTitle] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );
  const navigate = useNavigate();

  const getImageUrl = (source: string | undefined | null): string => {
    if (!source) return FALLBACK_IMAGE;
    if (source.startsWith('http') || source.startsWith('data:')) return source;
    if (source.startsWith('/static/')) return source; // Local fallback image
    return `${S3_BUCKET_URL}/${source}`;
  };

  const hasValidImage = (story: StoryPreview): boolean => {
    return !!story.current_node?.image_url && story.current_node.image_url !== FALLBACK_IMAGE;
  };

  const ImageWithFallback: React.FC<{ src: string | undefined | null; alt: string; className?: string }> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(src));
  
    const handleError = useCallback(() => {
      if (imgSrc !== FALLBACK_IMAGE) {
        console.error(`Failed to load image: ${imgSrc}`);
        console.error(`Original src: ${src}`);
        setImgSrc(FALLBACK_IMAGE);
      }
    }, [imgSrc, src]);
  
    useEffect(() => {
      const newSrc = getImageUrl(src);
      if (newSrc !== FALLBACK_IMAGE) {
        console.log(`Setting new image source: ${newSrc}`);
        setImgSrc(newSrc);
      }
    }, [src]);
  
    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={handleError}
        className={className}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  };

  useEffect(() => {
    fetchStoriesAndClasses();
    setIsAuthenticated(!!localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    const validImageStories = stories.filter(hasValidImage);
    if (validImageStories.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % validImageStories.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [stories]);

  const fetchStoriesAndClasses = async () => {
    setLoading(true);
    setError(null);
    try {
      const [storiesResponse, classesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/stories/popular/`),
        axios.get(`${API_BASE_URL}/classes/popular/`),
      ]);

      console.log("Stories API response:", JSON.stringify(storiesResponse.data, null, 2));
      console.log("Classes API response:", JSON.stringify(classesResponse.data, null, 2));

      if (Array.isArray(storiesResponse.data)) {
        const parsedStories = storiesResponse.data.map(story => ({
          ...story,
          current_node: typeof story.current_node === 'string' ? JSON.parse(story.current_node) : story.current_node
        }));
        setStories(parsedStories);
      } else {
        console.error("Unexpected stories data format:", storiesResponse.data);
        setError("Received unexpected data format for stories");
      }

      if (Array.isArray(classesResponse.data)) {
        setPopularClasses(classesResponse.data);
      } else {
        console.error("Unexpected classes data format:", classesResponse.data);
        setError("Received unexpected data format for classes");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (axios.isAxiosError(error)) {
        console.error("Axios error details:", error.response?.data);
      }
      setError("Failed to load content. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (path: string) => {
    if (isAuthenticated) {
      navigate(path);
    } else {
      navigate("/login", { state: { from: path } });
    }
  };

  const handleQuickCreate = () => {
    if (quickTitle.trim()) {
      if (isAuthenticated) {
        navigate(`/new?title=${encodeURIComponent(quickTitle.trim())}`);
      } else {
        navigate('/login', { 
          state: { 
            from: `/new?title=${encodeURIComponent(quickTitle.trim())}` 
          } 
        });
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error" sx={{ borderRadius: 2 }}>{error}</Alert>
      </Box>
    );
  }

  const validImageStories = stories.filter(hasValidImage);

  return (
    <Box sx={{ mt: 0 }}>
      {validImageStories.length > 0 && (
        <Box
          sx={{
            height: "60vh",
            position: "relative",
            overflow: "hidden",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 0,
            }}
          >
            <ImageWithFallback
              src={validImageStories[currentImageIndex]?.current_node?.image_url || FALLBACK_IMAGE}
              alt={validImageStories[currentImageIndex]?.title || "Story scene"}
              className="story-cover"
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1,
            }}
          />
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              textAlign: "center",
              color: "white",
              padding: 3,
            }}
          >
            <Typography variant="h2" component="h1" gutterBottom fontWeight="bold">
              Learn faster with Storybyte
            </Typography>
            <Typography variant="h5" gutterBottom>
              Create, explore, and share interactive educational stories
            </Typography>
            <Box sx={{ mt: 4, display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                variant="contained"
                size="large"
                startIcon={<Create />}
                onClick={() => handleAction("/new")}
                sx={{ minWidth: 200, borderRadius: 2 }}
              >
                Create Story
              </Button>
              <Button
                variant="contained"
                size="large"
                startIcon={<Explore />}
                onClick={() => handleAction("/library")}
                sx={{
                  minWidth: 200,
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": {
                    bgcolor: "primary.dark",
                  },
                  borderRadius: 2,
                }}
              >
                Explore Stories
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {/* Quick Start Section */}
      <Box sx={{ py: 4, bgcolor: "background.paper", borderRadius: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Quick Start Your Story
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Enter your story title"
            value={quickTitle}
            onChange={(e) => setQuickTitle(e.target.value)}
            sx={{ width: "50%", mr: 2, "& .MuiOutlinedInput-root": { borderRadius: 2 } }}
          />
          <Button
            variant="contained"
            onClick={handleQuickCreate}
            disabled={!quickTitle.trim()}
            sx={{ borderRadius: 2 }}
          >
            Create
          </Button>
        </Box>
      </Box>

      {/* Popular Classes Section */}
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Popular Classes
        </Typography>
        <Grid container spacing={4}>
          {popularClasses.map((classItem) => (
            <Grid item xs={12} sm={6} md={4} key={classItem.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 4,
                }}
              >
                <CardActionArea
                  onClick={() => handleAction(`/classes/${classItem.id}`)}
                  sx={{ flexGrow: 1 }}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {classItem.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, height: 60, overflow: "hidden" }}
                    >
                      {classItem.description}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        avatar={<Avatar>{classItem.owner[0]}</Avatar>}
                        label={classItem.owner}
                        variant="outlined"
                        sx={{ borderRadius: 4 }}
                      />
                      <Box>
                        <Chip
                          icon={<People />}
                          label={`${classItem.students_count} Students`}
                          size="small"
                          sx={{ mr: 1, borderRadius: 4 }}
                        />
                        <Chip
                          icon={<MenuBook />}
                          label={`${classItem.stories_count} Stories`}
                          size="small"
                          sx={{ borderRadius: 4 }}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* All Stories Section */}
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Explore Stories
        </Typography>
        <Grid container spacing={4}>
          {stories.map((story) => (
            <Grid item xs={12} sm={6} md={4} key={story.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 4,
                }}
              >
                <CardActionArea
                  onClick={() => handleAction(`/story/${story.id}`)}
                >
                  <Box sx={{ height: 140, overflow: 'hidden' }}>
                    <ImageWithFallback
                      src={story.current_node?.image_url || FALLBACK_IMAGE}
                      alt={story.title}
                      className="story-cover"
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div">
                      {story.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {story.context.substring(0, 100)}...
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        avatar={<Avatar>{story.author?.[0] || "A"}</Avatar>}
                        label={story.author || "Anonymous"}
                        variant="outlined"
                        sx={{ borderRadius: 4 }}
                      />
                      <Box>
                        <Chip
                          icon={<TrendingUp />}
                          label={`${story.plays} plays`}
                          size="small"
                          sx={{ mr: 1, borderRadius: 4 }}
                        />
                        <Chip
                          icon={<School />}
                          label={`${story.likes} likes`}
                          size="small"
                          sx={{ borderRadius: 4 }}
                        />
                      </Box>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;