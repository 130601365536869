import React, { useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import DiscordBanner from './components/DiscordBanner';
import Home from './components/Home';
import NewStory from './components/NewStory';
import Story from './components/Story';
import PersonalLibrary from './components/PersonalLibrary';
import Profile from './components/Profile';
import Navbar from './components/Navbar';
import Signup from './components/Signup';
import Login from './components/Login';
import RequestReset from './components/RequestReset';
import ResetPassword from './components/ResetPassword';
import StoryList from './components/StoryList';
import ClassLibrary from './components/ClassLibrary';
import CreateClass from './components/CreateClass';
import ClassEnrollment from './components/ClassEnrollment';
import ClassDetail from './components/ClassDetail';

// Configure axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_URL || '/api';

// Theme configuration
const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black
    },
    secondary: {
      main: '#ffffff', // White
    },
    background: {
      default: '#ffffff', // White background
      paper: '#f5f5f5', // Light grey for paper elements
    },
    text: {
      primary: '#000000', // Black text
      secondary: '#424242', // Dark grey for secondary text
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  // Protected route wrapper
  const ProtectedRoute = ({ children, redirectTo }: { children: React.ReactNode, redirectTo: string }) => {
    return isAuthenticated ? <>{children}</> : <Navigate to="/login" state={{ from: redirectTo }} />;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <DiscordBanner />
        <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} />
        <Container>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup onLogin={handleLogin} />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/forgot-password" element={<RequestReset />} />
            <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />

            {/* Protected Routes */}
            <Route
              path="/story/:id"
              element={
                <ProtectedRoute redirectTo="/story/:id">
                  <Story />
                </ProtectedRoute>
              }
            />
            <Route
              path="/new"
              element={
                <ProtectedRoute redirectTo="/new">
                  <NewStory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/library"
              element={
                <ProtectedRoute redirectTo="/library">
                  <StoryList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-library"
              element={
                <ProtectedRoute redirectTo="/my-library">
                  <PersonalLibrary />
                </ProtectedRoute>
              }
            />

            {/* Profile Routes */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Navigate to="/profile/stories" replace />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile/*"
              element={
                <ProtectedRoute redirectTo="/profile">
                  <Profile />
                </ProtectedRoute>
              }
            />

            {/* Class Routes */}
            <Route
              path="/classes"
              element={
                <ProtectedRoute redirectTo="/classes">
                  <ClassLibrary />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classes/create"
              element={
                <ProtectedRoute redirectTo="/classes/create">
                  <CreateClass />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classes/enroll"
              element={
                <ProtectedRoute redirectTo="/classes/enroll">
                  <ClassEnrollment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classes/:classId"
              element={
                <ProtectedRoute redirectTo="/classes/:classId">
                  <ClassDetail />
                </ProtectedRoute>
              }
            />
            
            {/* Catch-all redirect to home */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
};

export default App;