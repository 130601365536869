import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Rating, TextField, Button, Card, CardMedia, 
  List, ListItem, ListItemText, Divider, CircularProgress, Alert,
  Paper, Avatar
} from '@mui/material';
import axios from 'axios';

interface StoryEndProps {
  storyId: string;
  title: string;
  coverImage: string | null;
  onRestart: () => void;
}

interface Comment {
  id: number;
  user?: {
    id: number;
    username: string;
  };
  text: string;
  created_at: string;
  rating: number;
}

interface StoryData {
  comments: Comment[];
  rating: number;
  num_ratings: number;
  current_node?: {
    image_url?: string;
  };
  cover_image_url?: string;
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';

const StoryEnd: React.FC<StoryEndProps> = ({ storyId, title, coverImage: initialCoverImage, onRestart }) => {
  const [userRating, setUserRating] = useState<number | null>(null);
  const [comment, setComment] = useState('');
  const [storyData, setStoryData] = useState<StoryData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [coverImage, setCoverImage] = useState<string | null>(initialCoverImage);
  const navigate = useNavigate();

  console.log('Initial coverImage:', initialCoverImage);

  const getImageUrl = useCallback((url: string | null): string => {
    if (!url) return '';
    if (url.startsWith('http')) return url;
    return `${S3_BUCKET_URL}/${url.replace(/^\//, '')}`;
  }, []);

  const fetchStoryData = useCallback(async () => {
    try {
      const response = await axios.get<StoryData>(`${API_BASE_URL}/stories/${storyId}/`);
      console.log('Fetched story data:', JSON.stringify(response.data, null, 2));
      setStoryData(response.data);
      if (response.data.cover_image_url) {
        console.log('Setting cover image from cover_image_url:', response.data.cover_image_url);
        setCoverImage(response.data.cover_image_url);
      } else if (response.data.current_node?.image_url) {
        console.log('Setting cover image from current_node:', response.data.current_node.image_url);
        setCoverImage(response.data.current_node.image_url);
      } else {
        console.log('No cover image available');
        setCoverImage(null);
      }
    } catch (error) {
      console.error('Error fetching story data:', error);
      setError('Failed to load story data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [storyId]);

  useEffect(() => {
    fetchStoryData();
  }, [fetchStoryData]);

  const handleSubmit = async () => {
    if (userRating === null) return;
    setSubmitting(true);

    const optimisticComment: Comment = {
      id: Date.now(),
      user: { id: 0, username: 'You' },
      text: comment,
      created_at: new Date().toISOString(),
      rating: userRating,
    };

    setStoryData(prevData => {
      if (!prevData) return null;
      return {
        ...prevData,
        comments: [optimisticComment, ...prevData.comments],
        rating: (prevData.rating * prevData.num_ratings + userRating) / (prevData.num_ratings + 1),
        num_ratings: prevData.num_ratings + 1
      };
    });

    try {
      const response = await axios.post<{comment: Comment, new_rating: number, num_ratings: number}>(
        `${API_BASE_URL}/stories/${storyId}/comment/`,
        { rating: userRating, text: comment }
      );
      console.log('Comment submission response:', JSON.stringify(response.data, null, 2));
      
      setStoryData(prevData => {
        if (!prevData) return null;
        const newComment = response.data.comment;
        return {
          ...prevData,
          comments: [newComment, ...prevData.comments.filter(c => c.id !== optimisticComment.id)],
          rating: response.data.new_rating,
          num_ratings: response.data.num_ratings
        };
      });
      
      setUserRating(null);
      setComment('');
    } catch (error) {
      console.error('Error submitting rating and comment:', error);
      setError('Failed to submit rating and comment. Please try again.');
      setStoryData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          comments: prevData.comments.filter(c => c.id !== optimisticComment.id),
          rating: (prevData.rating * prevData.num_ratings - userRating) / (prevData.num_ratings - 1),
          num_ratings: prevData.num_ratings - 1
        };
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderComments = () => {
    if (!storyData || !storyData.comments || storyData.comments.length === 0) {
      return <Typography>No comments yet.</Typography>;
    }

    return storyData.comments.map((comment) => {
      if (!comment) {
        console.error('Encountered undefined comment');
        return null;
      }

      const username = comment.user?.username ?? 'Anonymous';
      const userInitial = username[0] ?? 'A';

      return (
        <React.Fragment key={comment.id}>
          <ListItem alignItems="flex-start">
            <Avatar sx={{ mr: 2 }}>{userInitial}</Avatar>
            <ListItemText
              primary={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1">
                    {username}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Rating 
                      value={comment.rating} 
                      readOnly 
                      size="small" 
                      precision={0.5} 
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      ({comment.rating})
                    </Typography>
                  </Box>
                </Box>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {new Date(comment.created_at).toLocaleString()}
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ mt: 1 }}>
                    {comment.text}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      );
    }).filter(Boolean);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!storyData) {
    return <Alert severity="warning">No story data available.</Alert>;
  }

  const averageRating = storyData.num_ratings > 0 ? storyData.rating / storyData.num_ratings : 0;

  const finalImageUrl = getImageUrl(coverImage);
  console.log('Final image URL:', finalImageUrl);

  return (
    <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" gutterBottom>
        Story Completed!
      </Typography>
      <Card sx={{ width: '100%', mb: 4 }}>
        <Box
          sx={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#f0f0f0',
            color: '#666',
            fontSize: '16px',
          }}
        >
          {finalImageUrl ? (
            <CardMedia
              component="img"
              height="300"
              image={finalImageUrl}
              alt={title}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                console.error(`Failed to load cover image:`, finalImageUrl);
                const target = e.target as HTMLImageElement;
                target.src = `${S3_BUCKET_URL}/path/to/fallback/image.jpg`;
              }}
            />
          ) : (
            'No image available'
          )}
        </Box>
      </Card>
      <Paper elevation={3} sx={{ p: 3, width: '100%', mb: 4 }}>
        <Typography variant="h6" gutterBottom>Overall Rating:</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Rating
            name="story-rating"
            value={averageRating}
            readOnly
            precision={0.1}
          />
          <Typography variant="body2" sx={{ ml: 1 }}>
            ({averageRating.toFixed(1)}) {storyData.num_ratings} ratings
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>Rate this story:</Typography>
        <Rating
          name="new-rating"
          value={userRating}
          onChange={(event, newValue) => {
            setUserRating(newValue);
          }}
          precision={0.5}
        />
        <TextField
          fullWidth
          label="Leave a comment"
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          margin="normal"
        />
        <Button 
          variant="contained" 
          onClick={handleSubmit} 
          sx={{ mt: 2, mb: 2 }} 
          disabled={userRating === null || submitting}
        >
          {submitting ? 'Submitting...' : 'Submit Rating and Comment'}
        </Button>
      </Paper>
      <Typography variant="h6" gutterBottom>Reviews and Comments:</Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {renderComments()}
      </List>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button variant="outlined" onClick={onRestart}>
          Restart Story
        </Button>
        <Button variant="contained" onClick={() => navigate('/')}>
          Go to Home Page
        </Button>
      </Box>
    </Box>
  );
};

export default StoryEnd;