import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Box, TextField } from '@mui/material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';

interface SignupProps {
  onLogin: () => void;
}

const Signup: React.FC<SignupProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    console.log('API_BASE_URL:', API_BASE_URL);
    console.log('Full signup URL:', `${API_BASE_URL}/accounts/signup/`);

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/signup/`, {
        username,
        email,
        password
      });
      
      console.log('Signup response:', response);
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      navigate('/');
    } catch (error) {
      console.error('Full error object:', error);
      if (axios.isAxiosError(error)) {
        console.error('Request config:', error.config);
        console.error('Response data:', error.response?.data);
        if (error.response) {
          setError(error.response.data.detail || 'An error occurred during signup');
        } else if (error.request) {
          setError('No response received from the server. Please try again.');
        } else {
          setError('Error setting up the request. Please try again.');
        }
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4, maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Sign Up
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <TextField
        fullWidth
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        margin="normal"
        required
        autoComplete="username"
      />
      <TextField
        fullWidth
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
        required
        autoComplete="email"
      />
      <TextField
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        required
        autoComplete="new-password"
      />
      <TextField
        fullWidth
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        margin="normal"
        required
        autoComplete="new-password"
      />
      <Button 
        type="submit" 
        variant="contained" 
        sx={{ mt: 2, width: '100%' }}
      >
        Sign Up
      </Button>
      <Button
        component={RouterLink}
        to="/login"
        variant="outlined"
        fullWidth
        sx={{ mt: 2 }}
      >
        Already have an account? Log in here
      </Button>
    </Box>
  );
};

export default Signup;