import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Grid, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

interface UserProfile {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

const ProfileInfo: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile>({ username: '', email: '', first_name: '', last_name: '' });
  const [editing, setEditing] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/accounts/profile/');
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        showSnackbar('Failed to fetch profile', 'error');
      }
    };

    fetchProfile();
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.put('/accounts/profile/', profile);
      setProfile(response.data);
      setEditing(false);
      showSnackbar('Profile updated successfully', 'success');
    } catch (error) {
      console.error('Error updating profile:', error);
      showSnackbar('Failed to update profile', 'error');
    }
  };

  const handleChangeEmail = async () => {
    try {
      await axios.put('/accounts/change-email/', { email: profile.email });
      showSnackbar('Email updated successfully', 'success');
    } catch (error) {
      console.error('Error changing email:', error);
      showSnackbar('Failed to change email', 'error');
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      showSnackbar('Passwords do not match', 'error');
      return;
    }
    try {
      await axios.post('/accounts/reset-password/', { new_password: newPassword });
      setNewPassword('');
      setConfirmPassword('');
      showSnackbar('Password reset successfully', 'success');
    } catch (error) {
      console.error('Error resetting password:', error);
      showSnackbar('Failed to reset password', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Profile Information</Typography>
      <Grid container spacing={2}>
        {editing ? (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Username"
                value={profile.username}
                onChange={(e) => setProfile({ ...profile, username: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={profile.first_name}
                onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={profile.last_name}
                onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleSave} variant="contained" sx={{ mr: 1 }}>
                Save
              </Button>
              <Button onClick={() => setEditing(false)} variant="outlined">
                Cancel
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography>Username: {profile.username}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Name: {profile.first_name} {profile.last_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => setEditing(true)} variant="contained">
                Edit Profile
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Email</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            value={profile.email}
            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleChangeEmail} variant="contained">
            Change Email
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Reset Password</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleResetPassword} variant="contained">
            Reset Password
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfileInfo;