import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';

interface StoryPreview {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  current_node?: {
    image_url: string;
  };
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

const PersonalLibrary: React.FC = () => {
  const [stories, setStories] = useState<StoryPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getImageUrl = (source: string | undefined | null): string => {
    if (!source) return FALLBACK_IMAGE;
    if (source.startsWith('http') || source.startsWith('data:')) return source;
    if (source.startsWith('/static/')) return source; // Local fallback image
    return `${S3_BUCKET_URL}/${source}`;
  };

  const ImageWithFallback: React.FC<{ src: string | undefined | null; alt: string; className?: string }> = ({ src, alt, className }) => {
    const [imgSrc, setImgSrc] = useState(() => getImageUrl(src));
  
    const handleError = () => {
      console.error(`Failed to load image: ${imgSrc}`);
      console.error(`Original src: ${src}`);
      setImgSrc(FALLBACK_IMAGE);
    };
  
    useEffect(() => {
      setImgSrc(getImageUrl(src));
    }, [src]);
  
    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={handleError}
        className={className}
        style={{ width: '100%', height: '140px', objectFit: 'cover' }}
      />
    );
  };

  useEffect(() => {
    const fetchPersonalLibrary = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${API_BASE_URL}/stories/personal_library/`);
        const parsedStories = response.data.map((story: any) => ({
          ...story,
          current_node: typeof story.current_node === 'string' ? JSON.parse(story.current_node) : story.current_node
        }));
        setStories(parsedStories);
      } catch (error) {
        console.error('Error fetching personal library:', error);
        setError('Failed to load your personal library. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPersonalLibrary();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error" sx={{ borderRadius: 2 }}>{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Library
      </Typography>
      <Grid container spacing={4}>
        {stories.map((story) => (
          <Grid item xs={12} sm={6} md={4} key={story.id}>
            <Card>
              <CardActionArea component={Link} to={`/story/${story.id}`}>
                <ImageWithFallback
                  src={story.current_node?.image_url}
                  alt={story.title}
                  className="story-cover"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {story.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {story.context.substring(0, 100)}...
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Plays: {story.plays} | Likes: {story.likes}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PersonalLibrary;