import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem, IconButton } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import storybyteIcon from '../assets/Asset 4.png';

interface NavbarProps {
  isAuthenticated: boolean;
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isAuthenticated, onLogout }) => {
  const navigate = useNavigate();
  const [libraryAnchorEl, setLibraryAnchorEl] = React.useState<null | HTMLElement>(null);
  const [classesAnchorEl, setClassesAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => 
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

  const handleMenuClose = (setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string, setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => () => {
    navigate(path);
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <IconButton
            component={RouterLink}
            to="/"
            sx={{ color: 'black', display: 'flex', alignItems: 'center' }}
          >
            <img src={storybyteIcon} alt="Storybyte Icon" style={{ height: '35px', marginRight: '15px' }} />
            <Typography variant="h4" component="div" sx={{ color: 'black', fontWeight: 'bold' }}>
              Storybyte
            </Typography>
          </IconButton>
        </Box>
        <Box>
          {isAuthenticated ? (
            <>
              <Button
                color="inherit"
                onClick={handleMenuOpen(setLibraryAnchorEl)}
                endIcon={<ExpandMoreIcon />}
                sx={{ color: 'black' }}
              >
                Library
              </Button>
              <Menu
                anchorEl={libraryAnchorEl}
                open={Boolean(libraryAnchorEl)}
                onClose={handleMenuClose(setLibraryAnchorEl)}
              >
                <MenuItem onClick={handleNavigation('/library', setLibraryAnchorEl)}>Public Library</MenuItem>
                <MenuItem onClick={handleNavigation('/my-library', setLibraryAnchorEl)}>My Library</MenuItem>
              </Menu>

              <Button
                color="inherit"
                onClick={handleMenuOpen(setClassesAnchorEl)}
                endIcon={<ExpandMoreIcon />}
                sx={{ color: 'black' }}
              >
                Classes
              </Button>
              <Menu
                anchorEl={classesAnchorEl}
                open={Boolean(classesAnchorEl)}
                onClose={handleMenuClose(setClassesAnchorEl)}
              >
                <MenuItem onClick={handleNavigation('/classes', setClassesAnchorEl)}>My Classes</MenuItem>
                <MenuItem onClick={handleNavigation('/classes/create', setClassesAnchorEl)}>Create Class</MenuItem>
                <MenuItem onClick={handleNavigation('/classes/enroll', setClassesAnchorEl)}>Enroll in Class</MenuItem>
              </Menu>

              <Button
                color="inherit"
                onClick={handleNavigation('/new', setLibraryAnchorEl)}
                sx={{ color: 'black' }}
              >
                New Story
              </Button>

              <Button
                color="inherit"
                onClick={handleMenuOpen(setProfileAnchorEl)}
                endIcon={<ExpandMoreIcon />}
                sx={{ color: 'black' }}
              >
                Profile
              </Button>
              <Menu
                anchorEl={profileAnchorEl}
                open={Boolean(profileAnchorEl)}
                onClose={handleMenuClose(setProfileAnchorEl)}
              >
                <MenuItem onClick={handleNavigation('/profile', setProfileAnchorEl)}>View Profile</MenuItem>
                <MenuItem onClick={handleNavigation('/profile/edit', setProfileAnchorEl)}>Edit Profile</MenuItem>
                <MenuItem onClick={() => { onLogout(); handleMenuClose(setProfileAnchorEl)(); }}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button color="inherit" component={RouterLink} to="/login" sx={{ color: 'black' }}>
                Login
              </Button>
              <Button color="inherit" component={RouterLink} to="/signup" sx={{ color: 'black' }}>
                Sign Up
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;