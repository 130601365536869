import React, { useState } from 'react';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import { Typography, Button, Box, TextField, Divider, Link } from '@mui/material';
import axios from 'axios';
import { setupAxios } from '../utils/auth';
import { API_BASE_URL } from '../config';

interface LoginProps {
  onLogin: () => void;
}

interface LocationState {
  from: string;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/accounts/login/`, {
        username: identifier, // Backend will handle both email and username
        password
      });
      
      localStorage.setItem('token', response.data.token);
      setupAxios();
      onLogin();
      const state = location.state as LocationState;
      navigate(state?.from || '/', { replace: true });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Login error response:', error.response);
        setError(error.response.data.detail || 'Invalid credentials');
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4, maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <TextField
        fullWidth
        label="Email or Username"
        value={identifier}
        onChange={(e) => setIdentifier(e.target.value)}
        margin="normal"
        required
        autoComplete="username"
        placeholder="Enter your email or username"
      />
      <TextField
        fullWidth
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        required
        autoComplete="current-password"
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 2 }}>
        <Link
          component={RouterLink}
          to="/forgot-password"
          sx={{
            fontSize: '0.875rem',
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Forgot password?
        </Link>
      </Box>
      <Button 
        type="submit" 
        variant="contained" 
        fullWidth
        sx={{ 
          mt: 1,
          py: 1.5,
          fontSize: '1rem',
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: 1.5,
        }}
      >
        Login
      </Button>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" color="text.secondary">
          OR
        </Typography>
      </Divider>
      <Button
        component={RouterLink}
        to="/signup"
        variant="outlined"
        fullWidth
        sx={{ 
          py: 1.5,
          fontSize: '1rem',
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: 1.5,
        }}
      >
        New user? Sign up here
      </Button>
    </Box>
  );
};

export default Login;