import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { 
  Box, Typography, Grid, Card, CardContent, CardMedia, CardActionArea, 
  CircularProgress, Alert, Button, Chip, Snackbar,
} from '@mui/material';
import axios from 'axios';

interface Story {
  id: number;
  title: string;
  context: string;
  plays: number;
  likes: number;
  cover_image?: string;
  current_node?: {
    image_url?: string;
  };
}

interface ClassDetails {
  id: number;
  name: string;
  description: string;
  class_code: string;
  is_public: boolean;
  owner: string;
  students_count: number;
  stories: Story[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const S3_BUCKET_URL = 'https://storybyte1.s3.amazonaws.com';
// Use a simple placeholder image as fallback
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNDAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2VlZSIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMjQiIGZpbGw9IiM5OTkiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGRvbWluYW50LWJhc2VsaW5lPSJtaWRkbGUiPk5vIGltYWdlIGF2YWlsYWJsZTwvdGV4dD48L3N2Zz4=';

const ClassDetail: React.FC = () => {
  const { classId } = useParams<{ classId: string }>();
  const navigate = useNavigate();
  const [classDetails, setClassDetails] = useState<ClassDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

  const getImageUrl = useCallback((story: Story): string => {
    // Check for valid story cover image
    if (story.cover_image) {
      if (story.cover_image.startsWith('http')) return story.cover_image;
      if (story.cover_image.startsWith('data:')) return story.cover_image;
      return `${S3_BUCKET_URL}/${story.cover_image}`;
    }

    // Check for valid story node image
    if (story.current_node?.image_url) {
      if (story.current_node.image_url.startsWith('http')) return story.current_node.image_url;
      if (story.current_node.image_url.startsWith('data:')) return story.current_node.image_url;
      return `${S3_BUCKET_URL}/${story.current_node.image_url}`;
    }

    // Return embedded fallback image if no valid image is found
    return FALLBACK_IMAGE;
  }, []);

  const fetchClassDetails = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<ClassDetails>(`${API_BASE_URL}/classes/${classId}/`);
      setClassDetails(response.data);
    } catch (error) {
      console.error('Error fetching class details:', error);
      setError('Failed to fetch class details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [classId]);

  useEffect(() => {
    fetchClassDetails();
  }, [fetchClassDetails]);

  const handleEnroll = async () => {
    try {
      await axios.post(`${API_BASE_URL}/classes/${classId}/enroll/`);
      setSnackbar({ open: true, message: 'Enrolled successfully!', severity: 'success' });
      fetchClassDetails();
    } catch (error) {
      console.error('Error enrolling in class:', error);
      setSnackbar({ open: true, message: 'Failed to enroll. Please try again.', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !classDetails) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error || 'Class not found'}</Alert>
        <Button component={Link} to="/classes" sx={{ mt: 2 }}>
          Back to Classes
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Button onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        Back to Classes
      </Button>
      
      <Typography variant="h4" component="h1" gutterBottom>
        {classDetails.name}
      </Typography>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
        <Chip 
          label={classDetails.is_public ? "Public" : "Private"} 
          color={classDetails.is_public ? "success" : "default"}
        />
        <Typography variant="body1">
          Class Code: {classDetails.class_code}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleEnroll}
          sx={{ ml: 'auto' }}
        >
          Enroll
        </Button>
      </Box>

      <Typography variant="body1" sx={{ mb: 2 }}>
        {classDetails.description}
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1">
          Owner: {classDetails.owner}
        </Typography>
        <Typography variant="subtitle1">
          Students: {classDetails.students_count}
        </Typography>
      </Box>

      <Typography variant="h5" component="h2" gutterBottom>
        Stories in this Class
      </Typography>

      {classDetails.stories.length === 0 ? (
        <Typography>No stories found in this class.</Typography>
      ) : (
        <Grid container spacing={3}>
          {classDetails.stories.map((story) => (
            <Grid item xs={12} sm={6} md={4} key={story.id}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardActionArea 
                  component={Link} 
                  to={`/story/${story.id}`}
                  sx={{ flexGrow: 1 }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={getImageUrl(story)}
                    alt={story.title}
                    sx={{ 
                      objectFit: 'cover',
                      bgcolor: '#f5f5f5'
                    }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {story.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      {story.context && story.context.length > 100
                        ? `${story.context.substring(0, 100)}...`
                        : story.context}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Plays: {story.plays} | Likes: {story.likes}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default ClassDetail;