import React, { useState, useEffect } from 'react';
import { Routes, Route, Link as RouterLink, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import ProfileInfo from './ProfileInfo';
import ProfileStories from './ProfileStories';

const Profile: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  // Set initial tab based on current route
  useEffect(() => {
    // Determine the active tab based on the path
    if (location.pathname === '/profile/stories') {
      setValue(0);
    } else if (location.pathname === '/profile/info') {
      setValue(1);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Navigate to the appropriate route when tab changes
    if (newValue === 0) {
      navigate('/profile/stories');
    } else {
      navigate('/profile/info');
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        My Profile
      </Typography>
      <Tabs value={value} onChange={handleChange} aria-label="profile tabs">
        <Tab label="My Stories" />
        <Tab label="Info" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        <Routes>
          {/* Redirect root profile path to stories */}
          <Route path="/" element={<Navigate to="stories" replace />} />
          <Route path="stories" element={<ProfileStories />} />
          <Route path="info" element={<ProfileInfo />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Profile;