import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, FormControlLabel, Switch, Alert, CircularProgress } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const CreateClass: React.FC = () => {
  const [className, setClassName] = useState('');
  const [classDescription, setClassDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCreateClass = async () => {
    setLoading(true);
    setError(null);

    try {
      await axios.post(`${API_BASE_URL}/classes/`, { 
        name: className, 
        description: classDescription,
        is_public: isPublic
      });
      navigate('/classes'); // Redirect to the class library after creating a class
    } catch (error) {
      console.error('Error creating class:', error);
      setError('Failed to create class. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Create a New Class</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <TextField
        fullWidth
        label="Class Name"
        value={className}
        onChange={(e) => setClassName(e.target.value)}
        margin="normal"
        required
      />
      <TextField
        fullWidth
        label="Class Description"
        value={classDescription}
        onChange={(e) => setClassDescription(e.target.value)}
        margin="normal"
        multiline
        rows={3}
      />
      <FormControlLabel
        control={
          <Switch
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            name="isPublic"
          />
        }
        label="Public Class"
      />
      <Box sx={{ mt: 2 }}>
        <Button 
          variant="contained" 
          onClick={handleCreateClass}
          disabled={loading || !className}
        >
          {loading ? <CircularProgress size={24} /> : 'Create Class'}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateClass;