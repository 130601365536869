import React, { useState, useEffect } from 'react';
import { Alert, Box, Link, IconButton, Collapse, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';

const DiscordBanner = () => {
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!localStorage.getItem('discordBannerClosed')) {
        setShow(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  
  const handleClose = () => {
    setShow(false);
    localStorage.setItem('discordBannerClosed', 'true');
  };

  return (
    <Collapse in={show}>
      <Alert 
        severity="info"
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                transform: 'rotate(90deg)',
                transition: 'transform 0.3s ease-in-out'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          borderRadius: 0,
          background: 'linear-gradient(90deg, #4A148C 0%, #7B1FA2 100%)',
          color: 'white',
          py: 1.5,
          '& .MuiAlert-message': {
            width: '100%',
          },
          '& .MuiAlert-action': {
            alignItems: 'center',
            paddingTop: 0
          },
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '2px',
            background: 'linear-gradient(90deg, #7B1FA2 0%, #9C27B0 100%)',
          }
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          <Typography 
            variant="h6" 
            component="span" 
            sx={{ 
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            🎓 UNLV Students! Help shape Storybyte -
          </Typography>
          <Link 
            href="https://discord.gg/nr3NPSYj" 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{ 
              color: '#7289DA',
              backgroundColor: 'white',
              padding: '6px 16px',
              borderRadius: '20px',
              textDecoration: 'none',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              transition: 'all 0.2s ease-in-out',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: '#f5f5f5',
                transform: 'translateY(-1px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
              }
            }}
          >
            <ChatIcon sx={{ fontSize: 20 }} />
            Join Discord
          </Link>
        </Box>
      </Alert>
    </Collapse>
  );
};

export default DiscordBanner;