import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  TextField,
  Grid,
  Chip,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";

interface Class {
  id: number;
  name: string;
  description: string;
  class_code: string;
  stories_count: number;
  is_public: boolean;
}

interface ApiResponse {
  owned_classes: Class[];
  enrolled_classes: Class[];
}

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const ClassLibrary: React.FC = () => {
  const [ownedClasses, setOwnedClasses] = useState<Class[]>([]);
  const [enrolledClasses, setEnrolledClasses] = useState<Class[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [enrollClassCode, setEnrollClassCode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching classes...");
      const response = await axios.get<ApiResponse>(
        `${API_BASE_URL}/classes/my_classes/`
      );
      console.log("API Response:", response.data);

      setOwnedClasses(response.data.owned_classes || []);
      setEnrolledClasses(response.data.enrolled_classes || []);

      console.log("Owned Classes:", response.data.owned_classes);
      console.log("Enrolled Classes:", response.data.enrolled_classes);

      // Log each class's public status
      response.data.owned_classes.forEach((c) =>
        console.log(`Class ${c.name} is_public:`, c.is_public)
      );
      response.data.enrolled_classes.forEach((c) =>
        console.log(`Class ${c.name} is_public:`, c.is_public)
      );
    } catch (error) {
      console.error("Error fetching classes:", error);
      setError("Failed to fetch classes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEnrollClass = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_BASE_URL}/classes/enroll/`, {
        class_code: enrollClassCode,
      });
      console.log("Enrollment response:", response.data);
      setEnrollClassCode("");
      fetchClasses(); // Refresh the class list after enrolling
      setError(`Success: ${response.data.message}`);
    } catch (error) {
      console.error("Error enrolling in class:", error);
      if (axios.isAxiosError(error) && error.response) {
        setError(
          `Error: ${error.response.data.error || "Failed to enroll in class"}`
        );
      } else {
        setError("Error: Failed to enroll in class. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const renderClassList = (classes: Class[], title: string) => (
    <>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {classes.length === 0 ? (
        <Typography>No classes found.</Typography>
      ) : (
        <Grid container spacing={2}>
          {classes.map((class_) => (
            <Grid item xs={12} sm={6} md={4} key={class_.id}>
              <Box
                sx={{
                  border: 1,
                  borderColor: "grey.300",
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <Typography variant="h6">{class_.name}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Chip
                    icon={class_.is_public ? <PublicIcon /> : <LockIcon />}
                    label={class_.is_public ? "Public" : "Private"}
                    color={class_.is_public ? "success" : "default"}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Code: {class_.class_code}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Stories: {class_.stories_count}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/classes/${class_.id}`)}
                  sx={{ mt: 1 }}
                >
                  View Class
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Classes
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Enroll in a Class
        </Typography>
        <TextField
          label="Class Code"
          value={enrollClassCode}
          onChange={(e) => setEnrollClassCode(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          onClick={handleEnrollClass}
          disabled={loading || !enrollClassCode}
          sx={{ ml: 2, mt: 2 }}
        >
          Enroll
        </Button>
      </Box>

      {error && (
        <Alert
          severity={error.startsWith("Success") ? "success" : "error"}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}

      {renderClassList(ownedClasses, "Classes You Own")}
      {renderClassList(enrolledClasses, "Classes You're Enrolled In")}
    </Box>
  );
};

export default ClassLibrary;
