import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Card, CardContent, CardMedia, Grid, Chip,
  IconButton, CircularProgress, Alert, Snackbar
} from '@mui/material';
import { Favorite, FavoriteBorder, BookmarkAdd, BookmarkAdded } from '@mui/icons-material';
import axios from 'axios';
import StoryEnd from './StoryEnd';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const FALLBACK_IMAGE = '/static/images/fallback_cover.png';

interface StoryData {
  id: number;
  title: string;
  current_node: StoryNode;
  current_scene: number;
  plays: number;
  likes: number;
  shares: number;
  cache_percentage: number;
  cover_image?: string;
  is_liked: boolean;
  is_saved: boolean;
  like_count: number;
}

interface StoryNode {
  content: string;
  choices: string[];
  scene_number: number;
  image_prompt: string;
  image_url: string;
  summary: string;
  characters: string[];
  parent_choice?: string;
}

interface FormattedTextProps {
  text: string;
}

const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
  const formatText = (content: string) => {
    // Split text into sentences and dialogues while preserving paragraph breaks
    const sentences = content.split(/(\n+|"[^"]+?")/g);
    
    return sentences.map((part: string, index: number) => {
      // Handle empty strings and whitespace
      if (!part.trim()) {
        return null;
      }
      
      // Check if this part is dialogue (wrapped in quotes)
      if (part.trim().startsWith('"') && part.trim().endsWith('"')) {
        // Find the speaking character by looking at the text after the dialogue
        const nextPart = sentences[index + 1] || '';
        const speakerMatch = nextPart.match(/,?\s*([\w\s]+)\s*(proclaimed|said|murmured|mused|chimed in|suggested|observed|whispered|asked|replied)/i);
        const speaker = speakerMatch ? speakerMatch[1].trim() : '';
        
        return (
          <div key={index} className="flex flex-col mb-4">
            <div className="flex items-start gap-2">
              {speaker && (
                <span className="text-sm font-semibold text-gray-800 mt-1 min-w-[100px]">
                  {speaker}:
                </span>
              )}
              <p className="text-lg flex-1">
                {part.trim()}
              </p>
            </div>
          </div>
        );
      } else if (part.trim()) {
        // For non-dialogue text (narration)
        return (
          <p key={index} className="text-gray-700 mb-4 leading-relaxed">
            {part.trim()}
          </p>
        );
      }
      return null;
    }).filter(Boolean); // Remove null elements
  };

  return (
    <div className="prose max-w-none">
      {formatText(text)}
    </div>
  );
};

const Story: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [story, setStory] = useState<StoryData | null>(null);
  const [currentNode, setCurrentNode] = useState<StoryNode | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showEnding, setShowEnding] = useState(false);
  const [failedImages, setFailedImages] = useState<Set<string>>(new Set());
  const [playRecorded, setPlayRecorded] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [resetCounter, setResetCounter] = useState(0);

  const getImageUrl = useCallback((url: string | undefined): string => {
    if (!url) return FALLBACK_IMAGE;
    if (url.startsWith('http') || url.startsWith('data:')) return url;
    if (process.env.NODE_ENV === 'production') {
      return `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/${url}`;
    } else {
      return `/media/${url}`;
    }
  }, []);

  const fetchStory = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<StoryData>(`${API_BASE_URL}/stories/${id}/`);
      setStory(response.data);
      setCurrentNode(response.data.current_node);
      console.log('Fetched story data:', response.data);
    } catch (error) {
      console.error('Error fetching story:', error);
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          setError('Story not found or has been removed due to incompleteness.');
        } else if (error.response.status === 401) {
          navigate('/login', { state: { from: `/story/${id}` } });
        } else {
          setError(`Failed to fetch story: ${error.response.data.detail || error.response.statusText}`);
        }
      } else {
        setError('Failed to fetch story. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchStory();
  }, [fetchStory, resetCounter]);

  useEffect(() => {
    if (currentNode && currentNode.image_url && !failedImages.has(currentNode.image_url)) {
      console.log('Current image URL:', getImageUrl(currentNode.image_url));
    }
  }, [currentNode, failedImages, getImageUrl]);

  const handlePlay = useCallback(async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/play/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        plays: response.data.plays
      }) : null);
    } catch (error) {
      console.error('Error incrementing play count:', error);
      setSnackbar({ open: true, message: 'Failed to record play. Please try again.' });
    }
  }, [id, story]);

  useEffect(() => {
    if (story && !playRecorded) {
      handlePlay();
      setPlayRecorded(true);
    }
  }, [story, playRecorded, handlePlay]);

  const handleChoice = async (choiceIndex: number) => {
    if (!id) return;
    setLoading(true);
    setError(null);
    try {
      console.log(`Making choice: index ${choiceIndex} for story ${id}`);
      const response = await axios.post<{ story: StoryData; is_conclusion: boolean; next_node: StoryNode }>(
        `${API_BASE_URL}/stories/${id}/make_choice/`,
        { choice_index: choiceIndex }
      );
      console.log('Server response:', response.data);

      if (response.data.story && response.data.next_node) {
        setStory(response.data.story);
        setCurrentNode(response.data.next_node);
        console.log('Updated story state:', {
          currentScene: response.data.story.current_scene,
          hasChoices: response.data.next_node.choices?.length > 0
        });
        setResetCounter(prev => prev + 1);
      } else {
        console.error('Unexpected response structure:', response.data);
        setError('Received unexpected data format from server');
      }
    } catch (error) {
      console.error('Error making choice:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          setError(`Failed to process choice: ${error.response.data.error || error.response.statusText}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          setError('No response received from server. Please check your connection and try again.');
        } else {
          console.error('Error setting up request:', error.message);
          setError('An error occurred while setting up the request. Please try again.');
        }
      } else {
        console.error('Non-Axios error:', error);
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRestart = async () => {
    if (!id) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/reset_story/`);
      console.log('Reset response:', response.data);
      setStory(response.data);
      setCurrentNode(response.data.current_node);
      setShowEnding(false);
      setPlayRecorded(false);
      setResetCounter(prev => prev + 1);
      console.log('Story reset, new image URL:', getImageUrl(response.data.current_node.image_url));
    } catch (error) {
      console.error('Error resetting story:', error);
      setError('Failed to reset story. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/like/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_liked: response.data.is_liked,
        like_count: response.data.like_count
      }) : null);
      setSnackbar({ open: true, message: response.data.is_liked ? 'Story liked!' : 'Story unliked.' });
    } catch (error) {
      console.error('Error liking story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to like story. Please try again.' });
      }
    }
  };

  const handleSave = async () => {
    if (!story || !id) return;
    try {
      const response = await axios.post<StoryData>(`${API_BASE_URL}/stories/${id}/save/`);
      setStory(prevStory => prevStory ? ({
        ...prevStory,
        is_saved: response.data.is_saved
      }) : null);
      setSnackbar({ open: true, message: response.data.is_saved ? 'Story saved!' : 'Story unsaved.' });
    } catch (error) {
      console.error('Error saving story:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        navigate('/login', { state: { from: `/story/${id}` } });
      } else {
        setSnackbar({ open: true, message: 'Failed to save story. Please try again.' });
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
        <Button onClick={() => navigate('/')} sx={{ mt: 2 }}>
          Back to Home
        </Button>
      </Box>
    );
  }

  if (!story || !currentNode) {
    return (
      <Box sx={{ mt: 4 }}>
        <Alert severity="warning">Story not found or has no content.</Alert>
        <Button onClick={() => navigate('/')} sx={{ mt: 2 }}>
          Back to Home
        </Button>
      </Box>
    );
  }

  if (showEnding) {
    return (
      <StoryEnd
        storyId={id || ''}
        title={story.title}
        coverImage={getImageUrl(story.cover_image) || getImageUrl(currentNode.image_url)}
        onRestart={handleRestart}
      />
    );
  }
  
  // Check for conclusion first
  const isConclusion = story.current_scene === 8;

  return (
    <Box sx={{ mt: 4, maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        {story.title}
      </Typography>
      <Card sx={{ mb: 4, boxShadow: 3 }}>
        <CardMedia
          key={`${resetCounter}-${currentNode.image_url}`}
          component="img"
          height="400"
          image={getImageUrl(currentNode.image_url) || getImageUrl(story.cover_image)}
          alt="Story scene"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            const imageUrl = currentNode.image_url || story.cover_image;
            if (imageUrl) {
              console.error(`Failed to load image: ${getImageUrl(imageUrl)}`);
              setFailedImages(prev => new Set(prev).add(imageUrl));
            }
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = FALLBACK_IMAGE;
          }}
        />
        <CardContent>
          <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', lineHeight: 1.6 }}>
            <FormattedText text={currentNode.content} />
          </Typography>
          {isConclusion ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setShowEnding(true)}
              sx={{ mt: 2 }}
            >
              Finish Story
            </Button>
          ) : currentNode.choices && currentNode.choices.length > 0 ? (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {currentNode.choices.map((choice, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleChoice(index)}
                    sx={{
                      height: 'auto',
                      minHeight: '60px',
                      whiteSpace: 'normal',
                      textAlign: 'left',
                      padding: '12px 16px',
                      lineHeight: 1.2,
                    }}
                  >
                    <FormattedText text={choice} />
                  </Button>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>This story has no choices available.</Typography>
          )}
        </CardContent>
      </Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleLike}>
            {story.is_liked ? <Favorite color="error" /> : <FavoriteBorder />}
          </IconButton>
          <Typography>{story.like_count} likes</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleSave}>
            {story.is_saved ? <BookmarkAdded color="primary" /> : <BookmarkAdd />}
          </IconButton>
          <Typography>Save</Typography>
        </Box>
        <Typography>Plays: {story.plays}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 1 }}>
          <Chip label={`Scene: ${story.current_scene}`} />
          <Chip label={`Plays: ${story.plays}`} />
          <Chip label={`Likes: ${story.like_count}`} />
          <Chip label={`Shares: ${story.shares}`} />
          <Chip label={`Cache: ${story.cache_percentage.toFixed(2)}%`} />
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="outlined" onClick={handleRestart}>
            Reset Story
          </Button>
          <Button variant="outlined" onClick={() => navigate('/')}>
            Go to Home Page
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Story;